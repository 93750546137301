@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


.top_fa_icon{
    color: #000;
    background-color: #fff;
    padding: 6px 6px;
    border-radius: 25px;
    font-size: 13px;
}



.banner_btn{
    border: #1093CC solid 2px !important;
    font-family: "Roboto", sans-serif !important;
    font-weight: 500 !important;
    color: #1093CC !important;
    padding: 6px 20px;
    border-radius: 7px;
    text-decoration: none;
}

.Banner_h1_dark{
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: #1D4A7C;
    margin-top: 38px;
    font-size: 47px;
}

.Banner{
    margin-top: 110px!important;
}

.Banner p{
    font-size: 16px;
    line-height: 30px;
    color: #000;
}

.Banner_h1_light{
    color: #1093CC;
}

.pointers_bg{
    background: linear-gradient(#183E67, #0F253E);
    border-radius: 5px;
    padding: 30px 0;
}

.pointers_bg p{
    padding-bottom: 20px;
}

.pointers_bg img{
    padding: 0 0 20px;
    width: 39px;
}

.mission_section{
    margin-top: 70px;
    margin-bottom: 70px;
}

.mission_section img{
    padding: 30px;
}

.mission_section h4{
    font-weight: 800;
    color: #1D4A7C;
    font-size: 30px;
    padding-bottom: 10px;
    padding-top: 10px;
}


.team_bg{
    background-color:#FFFBF7;
    border-radius: 5px;
    margin-top: 50px;
    margin-bottom: 40px;
    padding: 40px 0;
}

.team_bg p{
    padding-bottom: 20px;
}

.team_bg img{
    padding: 0 0 20px;
}


.team_bg h4{
    font-weight: 800;
    color: #1D4A7C;
    font-size: 20px;
    padding-bottom: 0px;
    padding-top: 10px;
}

.team_bg p{
    font-weight: 800;
    color: #5D5C5C;
    font-size: 15px;
    padding-bottom: 10px;
    padding-top: 0px;
    font-family: "Roboto", sans-serif!important;
    font-weight: 400!important;
}

.team_bg h2{
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: #1D4A7C;
    border-right: solid 1px #1D4A7C;
    padding: 10px 0 0 70px;
    font-size: 35px;
}

.team_btn{
    border: #16395F solid 2px!important;
    font-family: "Roboto", sans-serif!important;
    font-weight: 500!important;
    color: #16395F!important;
}

.team_pt{
    padding: 20px 0 0 0;
}

.message_bg{
    background: url("../images/message_bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

}

.message_bg h4{
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 35px;
    padding-top: 80px;
}

.message_bg p{
    font-family: "Roboto", sans-serif!important;
    font-weight: 400!important;
    font-size: 13px;
    padding-top: 10px;
    padding-bottom: 80px;
    line-height: 30px;
    color: #ffffff;
}

.Product_and_services_btn {
    border: #1093CC solid 2px!important;
    font-family: "Roboto", sans-serif!important;
    font-weight: 500!important;
    color: #1093CC!important;
    padding:5px 30px;
    margin-top: 20px;
}

.Product_and_services p{
    font-family: "Roboto", sans-serif!important;
    font-weight: 400!important;
    color: #5D5C5C;
    line-height: 30px;
}

.Footer_bg{
    background: linear-gradient(#183E67, #0F253E);
    margin-top: 50px;
    padding: 60px 0;
    color: #ffffff;
}

.footer_logo{
    width: 200px;
}

.Footer_bg h5{
    color: #FFFFFF;
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
}

.Footer_bg h5{
    font-weight: 600;
}

.Footer_bg_list{
    font-weight: 400;
}

.Footer_bg_list ul{
    font-weight: 400;
}

.Footer_bg ul li{
    font-weight: 400;
    font-size: 13px;
    display: block;
    padding-left: 22px;
}

.head_office{
    padding-left: 54px;
}

.footer_bottom{
    background-color:#FFFFFF ;
}

.footer_bottom p{
    color: #403C3C;
    font-family: "Roboto", sans-serif !important;
    font-weight: 400 !important;
    padding: 11px 0;
    text-align: center;
    margin: 0;
}

.page_heading{
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: #1D4A7C;
    margin-top: 20px;
    padding-bottom: 30px;
}

.page_heading2{
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: #1D4A7C;
    margin-top: 20px;
    padding-bottom: 0px;
}

.page_heading_light{
    color: #1093CC;
}

p{
    font-family: "Roboto", sans-serif!important;
    font-size: 14px;
    font-weight: 400;
    color: #5D5C5C;
    line-height: 25px;
}

.footer_road{
    width: 100%;
    margin-top: 50px;
    height: 142px;
}


.pointers_bg a{
    color: #ffffff!important;
    text-decoration: none;
}

.Home_products_text{
    font-family: "Roboto", sans-serif!important;
    font-size: 14px;
}

.logo{
    width: 300px;
}

.About_us h1{
    margin-top: 150px;
}

.About_us h2{
    padding-bottom: 30px;
    color: #5D5C5C;
    font-weight: 500;
}

.Product_and_services_img{
    width: 100%;
    padding: 20px 10px;
}

.ErosionControl_banner{
    background: url("../images/ErosionControl_banner.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-top: 130px;
    padding: 101px 0;
    border-radius: 10px;
}

.ErosionControl_banner h1{
    color: #ffffff!important;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: #1D4A7C;
    margin-top: 20px;
    padding-bottom: 0px;
}

.ErosionControl_banner h2{
    color: #ffffff!important;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: #1D4A7C;
    margin-top: 0px;
    padding-bottom: 0px;
}


.Crash_Barrier_banner{
    background: url("../images/img_2.webp");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-top: 130px;
    padding: 101px 0;
    border-radius: 10px;
}

.Crash_Barrier_banner h1{
    color: #ffffff!important;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: #1D4A7C;
    margin-top: 20px;
    padding-bottom: 0px;
}

.Crash_Barrier_banner h2{
    color: #ffffff!important;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: #1D4A7C;
    margin-top: 0px;
    padding-bottom: 0px;
}


.Re_wall_banner{
    background: url("../images/img_3.webp");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-top: 130px;
    padding: 101px 0;
    border-radius: 10px;
}

.Re_wall_banner h1{
    color: #ffffff!important;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: #1D4A7C;
    margin-top: 20px;
    padding-bottom: 0px;
}

.Re_wall_banner h2{
    color: #ffffff!important;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: #1D4A7C;
    margin-top: 0px;
    padding-bottom: 0px;
}

.Road_signage_banner{
    background: url("../images/img_4.webp");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-top: 130px;
    padding: 101px 0;
    border-radius: 10px;
}

.Road_signage_banner h1{
    color: #ffffff!important;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: #1D4A7C;
    margin-top: 20px;
    padding-bottom: 0px;
}

.Road_signage_banner h2{
    color: #ffffff!important;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: #1D4A7C;
    margin-top: 0px;
    padding-bottom: 0px;
}

.Fabrication_banner{
    background: url("../images/img_5.webp");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-top: 130px;
    padding: 101px 0;
    border-radius: 10px;
}

.Fabrication_banner h1{
    color: #ffffff!important;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: #1D4A7C;
    margin-top: 20px;
    padding-bottom: 0px;
}

.Fabrication_banner h2{
    color: #ffffff!important;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: #1D4A7C;
    margin-top: 0px;
    padding-bottom: 0px;
}

.RE_Block_banner{
    background: url("../images/img_6.webp");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-top: 130px;
    padding: 101px 0;
    border-radius: 10px;
}

.RE_Block_banner h1{
    color: #ffffff!important;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: #1D4A7C;
    margin-top: 20px;
    padding-bottom: 0px;
}

.RE_Block_banner h2{
    color: #ffffff!important;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: #1D4A7C;
    margin-top: 0px;
    padding-bottom: 0px;
}

.services_inner p{
    padding-top: 30px;
}

.navbar-collapse a{
    font-family: "Roboto", sans-serif!important;
    font-size: 16px;
    font-weight: 400;
    color: #062e55;
    text-decoration: none;
    padding: 8px 15px;
}

.dropdownt a{
    padding:8px 20px!important;
}

.top_mob_number{
    text-align: right;
}

.top_fa_icon2 {
    color: #000;
    background-color: #fff;
    padding: 6px 7px;
    border-radius: 25px;
    font-size: 13px;
}

.top_menu_bg{
    background: linear-gradient(#183E67, #0F253E);
    padding: 10px 0;
}

.Bannerimg{
    width: 100%;
    margin-bottom: -3px;
}

.Certificate_img{
    width: 100%;
    padding: 10px;
}

.Certificate_zoom_img{
    width: 100%;
}

.Clients_bg{
    background: url("../images/Ourclients.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.Client_heading{
    color: #ffffff;
    padding: 60px 0 60px 70px;
    font-family: "Poppins", sans-serif;
    font-size: 40px;
    font-weight: 600;
}

.Client_section{
    margin: 70px 0!important;
}

.Client_img{
    width: 100%;
    padding-top: 20px;
}

.Services_slide_heading{
    color: #fff !important;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 21px;
    position: absolute;
    bottom: 18px;
    padding: 0 20px;
    line-height: 31px;
}

.Services_slide_heading a{
    color: #fff !important;
    text-decoration: none;
    border: none!important;
    padding: 0px;
}

.top_contact_info{
    font-size: 14px;
}

.top_contact_info a{
    color: #fff;
    text-decoration: none;
}

.Certificate_page_margin{
    padding: 70px 0;
}

.Projectslider{
    margin-top: 70px;
}

.apply_now a {
    border: #1093CC solid 2px !important;
    font-family: "Roboto", sans-serif !important;
    font-weight: 500 !important;
    color: #1093CC !important;
    padding: 5px 30px;
}

.contact_form{
    margin: 30px 0;
}

.contact_form{
    padding: 60px 0;
}

.contant_submit_btn{
    border: #1093CC solid 2px !important;
    font-family: "Roboto", sans-serif !important;
    font-weight: 500 !important;
    color: #1093CC !important;
    padding: 6px 20px;
    border-radius: 7px;
    text-decoration: none;
}

.list-unstyled strong{
    color: #062e55;
}

.list-unstyled li{
    padding: 0 0 10px 0;
}

.top_menu_margin{
    margin-top:45px;
}

.map{
    width: 100%;
}

.gallery_heading{
    margin: 20px 0 20px 0;
}